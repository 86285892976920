import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import HomeIcon from "@material-ui/icons/Home";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";

import VisibilityIcon from "@material-ui/icons/Visibility";
import { FaRoad } from "react-icons/fa";

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import api from "../api";
import { ApiTracerVeiculos } from "../service/authtracer";
import axios from "axios";

export default function CardVeiculoPatio({ datafiltra, operacoes }) {
  const classes = useStyles();

  const [bancoSTRveiculos, setBancoSTRveiculos] = useState();
  const [veiculosComCarga, setVeiculosComCarga] = useState();
  const [veiculostracer, setVeiculostracer] = useState();
  const [origem, setOrigem] = useState();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //#########################DADOS DA API RASTREADOR###################################
  /*
 
*/
  function geolocalizacao(lat1, lon1, lat2, lon2) {
    // distance between latitudes
    // and longitudes
    let dLat = ((lat2 - lat1) * Math.PI) / 180.0;
    let dLon = ((lon2 - lon1) * Math.PI) / 180.0;

    // converte em radiano
    lat1 = (lat1 * Math.PI) / 180.0;
    lat2 = (lat2 * Math.PI) / 180.0;

    // aplica a formula
    let a =
      Math.pow(Math.sin(dLat / 2), 2) +
      Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
    let rad = 6371000; //circoferencia aproximada da terra, se querer passar para km cortar tres 0.
    let c = 2 * Math.asin(Math.sqrt(a));
    return rad * c;
  }

  // Função para buscar os dados do tracer
  const fetchTracerData = async () => {
    try {
      const res = await axios.get(ApiTracerVeiculos);
      const carg = res.data[0].items;

      const dados = carg.map((item) => ({
        placa: item.name,
        latitude: item.lat,
        longitude: item.lng,
        temperatura: item.temperature,
        velocidade: item.speed,
      }));

      setVeiculostracer(dados);
    } catch (err) {
      console.error("Erro ao buscar dados do tracer:", err);
    }
  };

  // useEffect para carregar os dados ao abrir a página e configurar o intervalo
  useEffect(() => {
    // Carrega os dados ao abrir a página
    fetchTracerData();

    // Configura o intervalo para atualizações automáticas
    const interval = setInterval(() => {
      fetchTracerData();
    }, 180000);

    // Limpa o intervalo ao desmontar o componente
    return () => clearInterval(interval);
  }, []);

  //incluir origem
  useEffect(() => {
    api
      .get("/origem")
      .then((res) => {
        let carg = res.data;

        let filterCerca = carg.filter((x) => x.cerca !== null);

        let pegaid = filterCerca.map(function (x) {
          return {
            id: x.id,
            descricao: x.descricao,
            lat: x.latitude,
            long: x.longitude,
            cerca: x.cerca,
            Cad_operacoes: x.Cad_operacoes,
          };
        });

        setOrigem(pegaid);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const fetchVeiculos = async () => {
      try {
        console.log("Buscando veículos..."); // Log para depuração
        const res = await api.get("/veiculos");

        const status = "Ativo";
        const nfrota = 200;
        const tipoveiculo = "CT";

        const filtro = res.data.filter(
          (x) =>
            x.status === status &&
            x.nfrota !== nfrota &&
            x.tipoveiculo !== tipoveiculo
        );

        setBancoSTRveiculos(filtro);
      } catch (err) {
        console.error("Erro ao buscar veículos:", err);
      }
    };

    fetchVeiculos(); // Executa a busca ao carregar a página

    const interval = setInterval(fetchVeiculos, 180000); // Atualização automática a cada 60s

    return () => clearInterval(interval); // Cleanup ao desmontar o componente
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get("/filterviagemmotorista");
        setVeiculosComCarga(res.data);
      } catch (err) {
        console.error("Erro ao buscar veículos com carga:", err);
      }
    };

    fetchData(); // Chamada inicial

    const interval = setInterval(fetchData, 180000); // Atualização automática a cada 1 min

    return () => clearInterval(interval); // Cleanup ao desmontar o componente
  }, []);

  function FiltraPlacas() {
    let a = bancoSTRveiculos;
    let b = veiculosComCarga;
    let Op = Number(operacoes);

    //filtra a operação e veiculos da base str tudo que é carreta e o que esta ativo
    let filterVeiculo = a?.filter((x) =>
      x.Cad_operacoes.some((i) => i.id === Op)
    );

    //pega apenas a placa das viagems com carga
    let dados = b?.map((item) => item.fkveiculo.placa);

    let placas = filterVeiculo?.filter((x) => !dados?.includes(x.placa));
    let contar = placas?.length;

    return contar;
  }

  function ContarDentroLocal() {
    let a = bancoSTRveiculos;
    let b = veiculosComCarga;
    let Op = Number(operacoes);

    //filtra a operação e veiculos da base str tudo que é carreta e o que esta ativo
    let filterVeiculo = a?.filter((x) =>
      x.Cad_operacoes.some((i) => i.id === Op)
    );

    // retirar a origem padrao
    let isorigem = origem?.filter((x) => x.id !== 3);

    //filtra a operação
    let filterorigem = isorigem?.filter((x) =>
      x.Cad_operacoes.some((i) => i.id === Op)
    );

    //pega apenas a placa das viagems com carga
    let dados = b?.map((item) => item.fkveiculo.placa);

    let placas = filterVeiculo?.filter((x) => !dados?.includes(x.placa));

    const placaencontrada = [];
    const placasNaoEncontradas = [];

    placas?.forEach((item) => {
      const encontrado = veiculostracer?.find((x) => x.placa === item.placa);

      if (encontrado) {
        placaencontrada.push({ ...item, ...encontrado }); // Mescla os objetos
      } else {
        placasNaoEncontradas.push(item); // Adiciona ao array de não encontradas
      }
    });

    const placasdistancia = [];
    const dentroDoRaio = [];
    const foraDoRaio = [];

    for (let i = 0; i < filterorigem?.length; i++) {
      for (let j = 0; j < placaencontrada?.length; j++) {
        const distanciaCalculada = geolocalizacao(
          filterorigem[i].lat,
          filterorigem[i].long,
          placaencontrada[j].latitude,
          placaencontrada[j].longitude
        );

        const placaInfo = {
          id: placaencontrada[j].id,
          placa: placaencontrada[j].placa,
          marca: placaencontrada[j].marca,
          tipoveiculo: placaencontrada[j].tipoveiculo,
          vinculo: placaencontrada[j].vinculo,
          descricaoOrigem: filterorigem[i].descricao,
          distancia: distanciaCalculada,
          nfrota: placaencontrada[j].nfrota,
          tipocarroceria: placaencontrada[j].tipocarroceria,
          qtdpallet: placaencontrada[j].qtdpallet,
          statusveiculo:
            distanciaCalculada <= 400 ? "Local de Coleta" : "Transito",
        };

        placasdistancia.push(placaInfo);

        if (distanciaCalculada <= 400) {
          dentroDoRaio.push(placaInfo);
        } else {
          foraDoRaio.push(placaInfo);
        }
      }
    }

    return dentroDoRaio.length;
  }

  function ExibirPlacas() {
    let a = bancoSTRveiculos;
    let b = veiculosComCarga;
    let Op = Number(operacoes);

    //filtra a operação e veiculos da base str tudo que é carreta e o que esta ativo
    let filterVeiculo = a?.filter((x) =>
      x.Cad_operacoes.some((i) => i.id === Op)
    );

    // retirar a origem padrao
    let isorigem = origem?.filter((x) => x.id !== 3);

    //filtra a operação
    let filterorigem = isorigem?.filter((x) =>
      x.Cad_operacoes.some((i) => i.id === Op)
    );

    //pega apenas a placa das viagems com carga
    let dados = b?.map((item) => item.fkveiculo.placa);

    let placas = filterVeiculo?.filter((x) => !dados?.includes(x.placa));

    const placaencontrada = [];
    const placasNaoEncontradas = [];

    placas?.forEach((item) => {
      const encontrado = veiculostracer?.find((x) => x.placa === item.placa);

      if (encontrado) {
        placaencontrada.push({ ...item, ...encontrado }); // Mescla os objetos
      } else {
        placasNaoEncontradas.push(item); // Adiciona ao array de não encontradas
      }
    });

    const placasdistancia = [];
    const dentroDoRaio = [];
    const foraDoRaio = [];

    for (let i = 0; i < filterorigem?.length; i++) {
      for (let j = 0; j < placaencontrada?.length; j++) {
        const distanciaCalculada = geolocalizacao(
          filterorigem[i].lat,
          filterorigem[i].long,
          placaencontrada[j].latitude,
          placaencontrada[j].longitude
        );

        const placaInfo = {
          id: placaencontrada[j].id,
          placa: placaencontrada[j].placa,
          marca: placaencontrada[j].marca,
          tipoveiculo: placaencontrada[j].tipoveiculo,
          vinculo: placaencontrada[j].vinculo,
          descricaoOrigem: filterorigem[i].descricao,
          distancia: distanciaCalculada,
          nfrota: placaencontrada[j].nfrota,
          tipocarroceria: placaencontrada[j].tipocarroceria,
          qtdpallet: placaencontrada[j].qtdpallet,
          statusveiculo:
            distanciaCalculada <= 400 ? "Local de Coleta" : "Transito",
        };

        placasdistancia.push(placaInfo);

        if (distanciaCalculada <= 400) {
          dentroDoRaio.push(placaInfo);
        } else {
          foraDoRaio.push(placaInfo);
        }
      }
    }

    let placasverificadas = [...foraDoRaio, ...dentroDoRaio];

    const newArray = placasverificadas.reduce((acc, item) => {
      let existing = acc.find((e) => e.placa === item.placa);
      if (!existing) {
        existing = {
          id: item.id,
          placa: item.placa,
          marca: item.marca,
          tipoveiculo: item.tipoveiculo,
          nfrota: item.nfrota,
          vinculo: item.vinculo,
          descricaoOrigem: item.descricaoOrigem,
          distancia: item.distancia,
          tipocarroceria: item.tipocarroceria,
          qtdpallet: item.qtdpallet,
          descricaodasorigens: [],
          statusveiculo: item.statusveiculo,
        };
        acc.push(existing);
      }

      // Verificar se a descricaoOrigem já existe antes de adicionar
      const descricaoJaExiste = existing.descricaodasorigens.some(
        (origem) => origem.descricaoOrigem === item.descricaoOrigem
      );

      if (!descricaoJaExiste) {
        existing.descricaodasorigens.push({
          distancia: item.distancia,
          descricaoOrigem: item.descricaoOrigem,
          statusveiculo: item.statusveiculo,
        });
      }

      return acc;
    }, []);

    console.log(JSON.stringify(dentroDoRaio, null, 2));

    return (
      <>
        <Typography></Typography>
        <TableContainer sx={{ maxHeight: 632, minWidth: 950 }}>
          <Table
            //  sx={{ minWidth: 950 }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">Placa</TableCell>
                <TableCell align="center">N Frota</TableCell>
                <TableCell align="center">Tipo</TableCell>
                <TableCell align="center">Modelo</TableCell>
                <TableCell align="center">Qtd Pallet</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newArray?.map((item) => (
                <TableRow>
                  <TableCell align="center">{item.placa}</TableCell>
                  <TableCell component="th" align="center">
                    {item.nfrota}
                  </TableCell>
                  <TableCell component="th" align="center">
                    {item.tipoveiculo}
                  </TableCell>
                  <TableCell component="th" align="center">
                    {item.tipocarroceria}
                  </TableCell>
                  <TableCell component="th" align="center">
                    {item.qtdpallet}
                  </TableCell>
                  {item.descricaodasorigens.map((x) => (
                    <TableCell style={{ padding: 1 }}>
                      {(() => {
                        let distancia = parseInt(x.distancia);
                        let calculo =
                          distancia === 9999999999 ? 0 : (distancia * 1) / 1000;
                        let descricao = x.descricaoOrigem;

                        return (
                          <>
                            <Typography
                              style={{
                                backgroundColor: "#415A77",
                                fontFamily: "serif",
                                color: "#fff",
                                borderColor: "#778DA9",
                                borderRadius: "4px",
                                justifyContent: "center",
                                display: "flex",
                                alignItems: "center",
                                //  justifyContent: "space-between", // Distribui espaço entre itens
                                textAlign: "center",
                                minWidth: 90,
                              }}
                            >
                              {descricao === 0 ? "Loading" : descricao}
                            </Typography>
                            <Typography
                              style={{
                                // backgroundColor: "#EDF1F6",
                                fontFamily: "serif",
                                //  color: "#fff",
                                borderColor: "#778DA9",
                                borderRadius: "4px",
                                justifyContent: "center",
                                display: "flex",
                                alignItems: "center",
                                //  justifyContent: "space-between", // Distribui espaço entre itens
                                textAlign: "center",
                                minWidth: 90,
                              }}
                            >
                              {x.statusveiculo == "Transito"
                                ? calculo
                                : x.statusveiculo}
                            </Typography>
                          </>
                        );
                      })()}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }

  let Veiculostb = FiltraPlacas();

  return (
    <Card
      style={{
        background: "#191E4D",
        justifyItems: "flex-start",
        justifyContent: "space-around",
        border: " 2px solid  #F4F6F9",
        borderRadius: 11,
        boxShadow: "1px 10px 10px #595959",
      }}
    >
      <CardContent>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Button className={classes.icon} onClick={handleClickOpen}>
            <VisibilityIcon className={classes.icon} />
          </Button>
          <Typography className={classes.qtd}>
            {Veiculostb == 0 ? 0 : Veiculostb}
          </Typography>
          <div
            style={{
              marginLeft: "auto",
              marginLeft: 20,
            }}
          >
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
              }}
            >
              <HomeIcon
                style={{
                  color: "#40bf80",
                  marginLeft: "auto",
                  transition: "transform 0.5s ease-in-out",

                  marginRight: 5,
                }}
              />
              <Typography
                style={{
                  color: "#fff",
                  marginLeft: "auto",
                  transition: "transform 0.5s ease-in-out",
                  // opacity: VericarCargaCard() ? 1 : 0,
                }}
              >
                {ContarDentroLocal()}
              </Typography>
            </div>
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                marginTop: 7,
              }}
            >
              <FaRoad
                style={{
                  color: "#40bf80",
                  marginLeft: "auto",
                  transition: "transform 0.5s ease-in-out",
                  fontSize: 20,
                  marginRight: 5,
                }}
              />
              <Typography
                style={{
                  color: "#fff",
                  marginLeft: "auto",
                  transition: "transform 0.5s ease-in-out",
                  // opacity: VericarCargaCard() ? 1 : 0,
                }}
              >
                {Veiculostb - ContarDentroLocal()}
              </Typography>
            </div>
          </div>
        </Box>
      </CardContent>

      <Divider className={classes.dividir} />
      <CardActions style={{ background: "#191E4D" }}>
        <Tooltip title=" Carretas Disponiveis">
          <Typography className={classes.title}>Carretas Disp.</Typography>
        </Tooltip>
        <Dialog
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Veiculos disponivel"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <ExibirPlacas />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fechar</Button>
          </DialogActions>
        </Dialog>
      </CardActions>
    </Card>
  );
}
const useStyles = makeStyles({
  root: {
    minWidth: 275,
    borderRadius: 8,
    borderColor: "red",
  },

  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  icon: {
    fontSize: 20,
  },
  title: {
    fontSize: 14,
    marginTop: -9,
    //marginLeft: -14,
    color: "#ffff",
    //  marginBottom: -20,
  },
  texto: {
    fontSize: 12,
    marginTop: -10,
    marginLeft: 48,
    color: "#ffff ",
  },
  pos: {
    marginBottom: 12,
  },

  dividir: {
    marginTop: -15,
    border: 10,
    backgroundColor: "#ffff",
  },
  qtd: {
    color: "#F5F5F5",
    fontSize: 40,
    fontWeight: "bold",
    marginTop: -5,
    //marginLeft: 42,
  },
  toolip: {
    minWidth: 950,
  },
});
