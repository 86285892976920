import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

//utils
import moment from "moment";
import "moment-duration-format";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import axios from "axios";
import api from "../api";
import "../style/style_pag.css";
import { ApiTracerVeiculos } from "../service/authtracer";

const useStyles = makeStyles({
  title: {
    fontSize: 14,
    marginTop: -9,
    //  marginLeft: 40,
    color: "#ffff",
    alignItems: "center",
  },
  cabecalhos: {
    color: "black",
    fontSize: 12,
    fontWeight: 750,
  },
});

export default function Dashtabelaveiculosdisponiveiscd() {
  const classes = useStyles();
  const [truck, setTruck] = useState();

  const [veiculos, setVeiculos] = useState();
  const [cargas, setCargas] = useState();

  function geolocalizacao(lat1, lon1, lat2, lon2) {
    // distance between latitudes
    // and longitudes
    let dLat = ((lat2 - lat1) * Math.PI) / 180.0;
    let dLon = ((lon2 - lon1) * Math.PI) / 180.0;

    // converte em radiano
    lat1 = (lat1 * Math.PI) / 180.0;
    lat2 = (lat2 * Math.PI) / 180.0;

    // aplica a formula
    let a =
      Math.pow(Math.sin(dLat / 2), 2) +
      Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
    let rad = 6371000; //circoferencia aproximada da terra, se querer passar para km cortar tres 0.
    let c = 2 * Math.asin(Math.sqrt(a));
    return rad * c;
  }

  let latcd = -27.224884; //ponto fixo
  let loncd = -48.621497; //ponto fixo
  let cerca = 420.67600239642678; // area do cd
  //incluir veiculo
  useEffect(() => {
    axios
      .get(ApiTracerVeiculos)
      .then((res) => {
        let carg = res.data[0].items;

        //let distancia = geolocalizacao(latcd, loncd, lat2, lon2)
        //let teste = data.map((item) => 'Placa:'+item.name +  ' longitude:' + item.lng  +' latitude:' + item.lat )
        let dados = carg.map((item) => [item.name, item.lat, item.lng]);

        let dados1 = dados
          .map(([placa, latitude, longitude]) => ({
            placa,
            latitude,
            longitude,
            distancia: geolocalizacao(latitude, longitude, latcd, loncd),
          })) // calcula a distancia de cada placa
          .filter(
            ({ placa, latitude, longitude, distancia }) => distancia <= cerca
          ) // filtra somente as placas que estão no cerco
          .map(
            ({ placa, latitude, longitude }) => placa
            // latitude,
            //longitude,
          ); // recupera somente o valor latitude e longitud
        // console.log(dados1);
        setVeiculos(dados1);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //busca automatico
  useEffect(() => {
    const interval = setInterval(() => {
      axios
        .get(ApiTracerVeiculos)
        .then((res) => {
          let carg = res.data[0].items;

          //let distancia = geolocalizacao(latcd, loncd, lat2, lon2)
          //let teste = data.map((item) => 'Placa:'+item.name +  ' longitude:' + item.lng  +' latitude:' + item.lat )
          let dados = carg.map((item) => [item.name, item.lat, item.lng]);

          let dados1 = dados
            .map(([placa, latitude, longitude]) => ({
              placa,
              latitude,
              longitude,
              distancia: geolocalizacao(latitude, longitude, latcd, loncd),
            })) // calcula a distancia de cada placa
            .filter(
              ({ placa, latitude, longitude, distancia }) => distancia <= cerca
            ) // filtra somente as placas que estão no cerco
            .map(
              ({ placa, latitude, longitude }) => placa
              // latitude,
              //longitude,
            ); // recupera somente o valor latitude e longitud
          // console.log(dados1);
          setVeiculos(dados1);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  //incluir veiculo
  useEffect(() => {
    api
      .get("/dashfrotatruckcd")
      .then((res) => {
        let carg = res.data[0];
        // let status = 'Ativo';
        let tipo = "CT"; //parametro para filtro
        let nfrota = 200;
        let dados = carg.map((item) => item);
        let filtro = dados.filter((x) => x.tipoveiculo == tipo);
        let filtro1 = filtro.filter((x) => x.nfrota !== nfrota);

        setTruck(filtro1);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //busca automatico
  useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/dashfrotatruckcd")
        .then((res) => {
          let carg = res.data[0];
          // let status = 'Ativo';
          let tipo = "CT"; //parametro para filtro
          let nfrota = 200;
          let dados = carg.map((item) => item);
          let filtro = dados.filter((x) => x.tipoveiculo == tipo);
          let filtro1 = filtro.filter((x) => x.nfrota !== nfrota);

          setTruck(filtro1);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  //incluir veiculo
  useEffect(() => {
    api
      .get("/filterviagemmotorista")
      .then((res) => {
        let carg = res.data;
        let id = 28;
        let filtrardados = carg.filter((item) => item.idveiculotrator !== id);
        let placa = filtrardados.map((x) => x.fkveiculotrator.placa);
        setCargas(placa);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //busca automatico
  useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/filterviagemmotorista")
        .then((res) => {
          let carg = res.data;
          let id = 28;
          let filtrardados = carg.filter((item) => item.idveiculotrator !== id);
          let placa = filtrardados.map((x) => x.fkveiculotrator.placa);
          setCargas(placa);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  //filterviagemmotorista

  function FiltraPlacas() {
    let vei = veiculos;
    if (vei) {
      return truck?.filter((x) => vei.includes(x.placa));
    } else {
      console.log(veiculos);
    }
  }
  let FiltroTruck = FiltraPlacas();

  function MetodoPrincipal() {
    let dados = FiltroTruck;

    if (dados) {
      return dados?.filter((x) => !cargas?.includes(x.placa));
    } else {
      console.log(veiculos);
    }
  }

  let Dadosapresentar = MetodoPrincipal();

  function CountTrucks() {
    let dados = Dadosapresentar;

    return dados?.length;
  }

  return (
    <Card
      style={{
        background: "#33415C",
        justifyItems: "flex-start",
        justifyContent: "space-around",
        border: " 2px solid  #F4F6F9",
        borderRadius: 11,
      }}
    >
      <div
        style={{
          background: "#33415C",
          flexFlow: "nowrap",
          justifyContent: "flex-end",
          color: "white",
          font: "monospace",
          display: "flex",
        }}
      >
        <Typography
          style={{
            height: 35,
            marginRight: 180,
            fontSize: 20,
            marginTop: 5,
            marginLeft: 5,
          }}
        >
          Trucks Disponiveis CD
        </Typography>
        <Typography
          style={{
            fontSize: 22,
            marginRight: 10,
            marginTop: 5,
          }}
        >
          Total :
        </Typography>
        <Typography
          style={{
            marginRight: 25,
            fontSize: 22,
            marginTop: 5,
          }}
        >
          {CountTrucks() == 0 ? 0 : CountTrucks()}
        </Typography>
      </div>
      <TableContainer className={classes.root} sx={{ maxHeight: 187 }}>
        <Table
          sx={{
            //  width: '100%',
            // maxWidth: 360,
            bgcolor: "background.paper",
            //  position: 'relative',
            overflow: "auto",
            maxHeight: 183,
            // minHeight: 183,
            minWidth: 140,
          }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography className={classes.cabecalhos}>Placa</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography className={classes.cabecalhos}>N Frota</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.cabecalhos}>Tempo</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Dadosapresentar?.map((item) => (
              <TableRow key={item.id}>
                <TableCell align="center">
                  {(() => {
                    if (item) {
                      return (
                        <Typography
                          className="textoareaplacatruck"
                          style={{
                            fontSize: 15,
                          }}
                        >
                          {item.placa}
                        </Typography>
                      );
                    } else {
                      return (
                        <Typography
                          className="tempocritico"
                          style={{
                            fontSize: 15,
                            backgroundColor: "red",
                          }}
                        >
                          Sem Truck no CD
                        </Typography>
                      );
                    }
                  })()}
                </TableCell>
                <TableCell align="left">
                  <Typography
                    className="textoareanfrota"
                    style={{
                      fontSize: 20,
                    }}
                  >
                    {item.nfrota}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  {(() => {
                    //hora atual

                    let horsea = new Date();
                    let [diaa, mesa, anoa] = new Date()
                      .toLocaleDateString("pt-BR")
                      .split("/");
                    let formatarhoraatual =
                      diaa +
                      "-" +
                      mesa +
                      "-" +
                      anoa +
                      " " +
                      horsea.getHours() +
                      ":" +
                      horsea.getMinutes() +
                      ":" +
                      horsea.getSeconds();

                    //hora atual
                    let horaatual = Date();
                    //data atual
                    var timehoraatual = new Date(horaatual);
                    var outrahoraatual = new Date(horaatual);
                    outrahoraatual.setHours(timehoraatual.getHours()); // Adiciona 2 horas
                    outrahoraatual.setMonth(timehoraatual.getMonth() + 1);

                    let horse = new Date(item.dtentrada);
                    let [dia, mes, ano] = new Date(item.dtentrada)
                      .toLocaleDateString("pt-BR")
                      .split("/");
                    let formatardataapi =
                      dia +
                      "-" +
                      mes +
                      "-" +
                      ano +
                      " " +
                      horse.getUTCHours() +
                      ":" +
                      horse.getUTCMinutes() +
                      ":" +
                      horse.getUTCSeconds();

                    //teste de calculo tempo de cd
                    var ms = moment(
                      formatarhoraatual,
                      "DD/MM/YYYY HH:mm:ss"
                    ).diff(moment(formatardataapi, "DD/MM/YYYY HH:mm:ss"));
                    var d = moment.duration(ms);
                    var tempo =
                      Math.floor(d.asHours()) + moment.utc(ms).format(":mm");

                    if (item) {
                      return (
                        <Typography
                          className="textoareanfrota"
                          style={{
                            fontSize: 15,
                          }}
                        >
                          {tempo}
                        </Typography>
                      );
                    } else {
                      return (
                        <Typography
                          className="tempocritico"
                          style={{
                            fontSize: 15,
                            backgroundColor: "red",
                          }}
                        >
                          00:00
                        </Typography>
                      );
                    }
                  })()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
